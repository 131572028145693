import React from "react";

const Menu: React.FC = () => {
  return (
    <div id="menu">
      <ul id="nav-items">
        <li className="nav-item">
          <a href="/">Home</a>
        </li>
        <li className="nav-item">
          <a href="./about">About</a>
        </li>
        <li className="nav-item">
          <a href="./other-works">Other Works</a>
        </li>
        <li className="nav-item">
          <a href="./merch">Merch</a>
        </li>
      </ul>
    </div>
  );
};

export default Menu;
