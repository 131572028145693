import React from "react";

const About: React.FC = () => {
  return (
    <section id="about-container">
      <div id="about">
        <div id="artist-bio">
          <p>
            Luthier is the hybrid electro-acoustic sound project of musician and
            artist Luis Robayo. The work of Luthier is simultaneously rooted in
            themes of religion, ancestry, mental health, death, and celebration
            of life. Flowing from ambient music to experimental, melody based
            pop music, the sonic aesthetic is ever changing and amorphous.
            Inspired by the tradition of minimal composers of the 20th century
            such as Steve Reich and Terry Riley, repetition is utilized in order
            to draw the listener into a focused, present lull. Although
            minimalistic in their melodic structure and use of repetition, the
            arrangements and layering of Luthier’s work are often maximalist in
            nature to evoke a sense of scale for a listener. This project is an
            attempt to unearth and translate the mute processes of our world and
            minds into something harmonically tangible. Voice, modular
            synthesizer, harmonium, flute, hammered dulcimer, and piano are
            among the many tools that define the sound of Luthier’s first
            album-format body of work, ‘Open World’.
          </p>
        </div>
        <div id="album-intro">
          <p>A note on open world...</p>
        </div>
        <div id="album-bio">
          <p>
            "I heard a story once, a great open world. Sprawling, no borders.
            Water." This short poem in the song, "Running Around'' contains the
            last audible words on Open World and elucidates the environment in
            which the listener resides. A concept album through and through,
            Open World attempts to illustrate an 'ultra-reality' through sound.
            This reality is one where all silent things are spoken, and every
            muted natural process of the real world is clear for all to hear.
            Thoughts, feelings, death, birth, color, shape, texture, and
            millions of other silent processes resound for the ear. The project
            begins with 'Open Gate' which scores the imaginary process of a gate
            opening to the realm or ultra-reality in which this album takes
            place. As the project goes on, the listener experiences more and
            more of this reality before being forcefully pulled out in the final
            piece, "Running Around".
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
