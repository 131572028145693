import React from "react";
import "./index.css";
import { Route, Routes, useLocation } from "react-router-dom";
import OtherWorks from "./components/OtherWorks";
import Merch from "./components/Merch";
import About from "./components/About";
import Home from "./components/Home";
import Nav from "./components/nav/Nav";
import Lofi from "./components/LoFi";
import Footer from "./components/Footer";
import "./App.css";
import Socials from "./components/nav/Socials";

const App: React.FC = () => {
  const location = useLocation();

  return (
    <div>
      <Nav />
      <Socials />
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home />} />
        <Route path="/lofi" element={<Lofi />} />
        <Route path="/about" element={<About />} />
        <Route path="/other-works" element={<OtherWorks />} />
        <Route path="/merch" element={<Merch />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
