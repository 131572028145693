import React from "react";

const Footer: React.FC = () => {
  return (
    <footer>
      <p>&copy; 2024 Luthier. All rights reserved. Art by Grace Cope.</p>
    </footer>
  );
};

export default Footer;
