import Hamburger from "./Hamburger";
import Logo from "./Logo";
import Menu from "./Menu";
// import Socials from "./Socials";

const Nav: React.FC = () => {
  window.addEventListener("load", () => {
    document.body.style.color = "#000";
  });

  return (
    <div className="nav-bar">
      <Menu />
      <Logo />
      <Hamburger />
    </div>
  );
};

export default Nav;
