import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faBandcamp,
  faSpotify,
  faSoundcloud,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import React from "react";

const Socials: React.FC = () => {
  return (
    <div id="socials">
      <a href="https://www.instagram.com/_luthier/" target="_blank">
        <FontAwesomeIcon icon={faInstagram} />
      </a>
      <a
        href="https://open.spotify.com/artist/1YWcmUgloxkEXw0KNduBNm?si=EjcVjTxITrSnpPTo-VQyXg"
        target="_blank"
      >
        <FontAwesomeIcon icon={faSpotify} />
      </a>
      <a href="https://luthier.bandcamp.com/" target="_blank">
        <FontAwesomeIcon icon={faBandcamp} />
      </a>
      <a href="https://soundcloud.com/luthiersound" target="_blank">
        <FontAwesomeIcon icon={faSoundcloud} />
      </a>
      <a href="mailto:luthiersound@gmail.com">
        <FontAwesomeIcon icon={faEnvelope} />
      </a>
    </div>
  );
};

export default Socials;
