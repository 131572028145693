import React, { useEffect, useState } from "react";
import severalBirdsTshirtFront from "../img/merch/several-birds-t-shirt-front.png";
import severalBirdsTshirtBack from "../img/merch/several-birds-t-shirt-back.png";
import toteBag from "../img/merch/tote-bag.png";

interface Props {}

interface MerchItem {
  title: string;
  link: string;
  img: string[];
  price: string;
  id: string;
}

const merch: MerchItem[] = [
  {
    title: "Several Birds T-Shirt",
    link: "https://bd1429-98.myshopify.com/products/several-birds-shirt?utm_source=copyToPasteBoard&utm_medium=product-links&utm_content=web",
    img: [severalBirdsTshirtFront, severalBirdsTshirtBack],
    price: "€20,00",
    id: "several-birds-t-shirt",
  },
  {
    title: "Open World Tote",
    link: "https://bd1429-98.myshopify.com/products/open-world-tote?utm_source=copyToPasteBoard&utm_medium=product-links&utm_content=web",
    img: [toteBag],
    price: "€20,00",
    id: "tote-bag",
  },
];

const Merch: React.FC<Props> = () => {
  function onHover(merchItem: MerchItem) {
    const img = document.getElementById(merchItem.id);
    if (img) {
      img.setAttribute("src", merchItem.img[1] ?? merchItem.img[0]);
    }
  }

  function offHover(merchItem: MerchItem) {
    const img = document.getElementById(merchItem.id);
    if (img) {
      img.setAttribute("src", merchItem.img[0]);
    }
  }

  return (
    <section id="merch">
      <div className="merch-container">
        {merch.map((merchItem, index) => (
          <div key={index}>
            <a href={merchItem.link} target="_blank">
              <img
                src={merchItem.img[0]}
                id={merchItem.id}
                onMouseOver={() => onHover(merchItem)}
                onMouseLeave={() => offHover(merchItem)}
              ></img>
              <h4>{merchItem.title}</h4>
              <p>{merchItem.price}</p>
            </a>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Merch;
