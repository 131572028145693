import React from "react";

const OtherWorks: React.FC = () => {
  return (
    <section id="other-works">
      <h1>Other Works</h1>
      <div className="vid-container">
        <div className="vid-item">
          <iframe
            src="https://player.vimeo.com/video/678969388?h=4ad319894d"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <div className="vid-item">
          <iframe
            src="https://player.vimeo.com/video/928858441?h=b771bd2c36"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <div className="vid-item">
          <iframe
            title="vimeo-player"
            src="https://player.vimeo.com/video/981865561?h=dcbc2d1651"
            allowFullScreen
          ></iframe>
        </div>
        <div className="vid-item">
          <iframe
            title="vimeo-player"
            src="https://player.vimeo.com/video/981895559?h=7c582269ec"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default OtherWorks;
