import React from "react";

const Luthier: React.FC = () => {
  return (
    <div className="" id="luthier">
      <a href="/">Luthier</a>
    </div>
  );
};

export default Luthier;
