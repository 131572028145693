// import { ImagePixelated } from "react-pixelate";
import hifi from "./img/album-cover.jpeg";
import hifi10 from "./img/hifi-10.jpg";
import hifi20 from "./img/hifi-20.jpg";
import hifi30 from "./img/hifi-30.jpg";
import hifi40 from "./img/hifi-40.jpg";
import hifi50 from "./img/hifi-50.jpg";
import hifi60 from "./img/hifi-60.jpg";
import hifi70 from "./img/hifi-70.jpg";
import hifi80 from "./img/hifi-80.jpg";
import hifi90 from "./img/hifi-90.jpg";
import hifi100 from "./img/hifi-100.jpg";
import lofi from "./img/lo-fi-album-cover.jpg";
import lofi10 from "./img/lofi-10.jpg";
import lofi20 from "./img/lofi-20.jpg";
import lofi30 from "./img/lofi-30.jpg";
import lofi40 from "./img/lofi-40.jpg";
import lofi50 from "./img/lofi-50.jpg";
import lofi60 from "./img/lofi-60.jpg";
import lofi70 from "./img/lofi-70.jpg";
import lofi80 from "./img/lofi-80.jpg";
import lofi90 from "./img/lofi-90.jpg";
import lofi100 from "./img/lofi-100.jpg";

export const getPixelatedHi2Lo = () => {
  return [
    hifi,
    hifi10,
    hifi20,
    hifi30,
    hifi40,
    hifi50,
    hifi60,
    hifi70,
    hifi80,
    hifi90,
    hifi100,
    lofi100,
    lofi90,
    lofi80,
    lofi70,
    lofi60,
    lofi50,
    lofi40,
    lofi30,
    lofi20,
    lofi10,
    lofi,
  ];
};

export const getPixelatedLo2Hi = () => {
  return [
    lofi,
    lofi10,
    lofi20,
    lofi30,
    lofi40,
    lofi50,
    lofi60,
    lofi70,
    lofi80,
    lofi90,
    lofi100,
    hifi100,
    hifi90,
    hifi80,
    hifi70,
    hifi60,
    hifi50,
    hifi40,
    hifi30,
    hifi20,
    hifi10,
    hifi,
  ];
};

export function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

