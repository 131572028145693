import React, { useEffect, useState } from "react";
import { Cross as BurgerMenu } from "hamburger-react";

const Hamburger: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const menu = document.getElementById("menu");
    if (!menu) {
      return;
    }

    const windowSize = window.innerWidth;
    const verticalMenuDesktop = windowSize <= 1024 && windowSize > 960;
    const mobileMenu = windowSize <= 960;

    if (isMenuOpen) {
      if (mobileMenu) {
        menu.style.height = "max-content";
        menu.style.width = "100vw";
        menu.style.padding = "1.1vh 0 0 0";
      } else {
        menu.style.width = "max-content";
        menu.style.height = "max-content";
      }
    } else {
      if (verticalMenuDesktop || mobileMenu) {
        menu.style.height = "0";
        menu.style.width = "max-content";
        menu.style.padding = "0";
      } else {
        menu.style.width = "0";
        menu.style.height = "max-content";
      }
    }
  }, [isMenuOpen]);

  const handleToggle = (toggled: boolean) => {
    setIsMenuOpen(toggled);
  };

  return (
    <div id="hamburger">
      <BurgerMenu
        direction="left"
        distance="sm"
        hideOutline={true}
        onToggle={(toggled) => handleToggle(toggled)}
      />
    </div>
  );
};

export default Hamburger;
