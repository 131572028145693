import React from "react";
import Luthier from "./Luthier";
import "./../index.css";
import { Link } from "react-router-dom";

const Home: React.FC = () => {
  // $(document).on("ready", () => {
  //   $(".home-link").on("click", () => {
  //     $(".home-content")
  //       .addClass("magictime")
  //       .on(
  //         "transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd",
  //         () => {
  //           alert("load next page here");
  //         }
  //       );
  //   });
  // });

  return (
    <section id="home" className="home-content">
      <Luthier />
      <div className="" id="lofi-link">
        <Link to="/lofi" id="lofi-inner">
          <span></span>
        </Link>
      </div>
      <div className="" id="essays-link">
        <Link to="/" id="essays-inner">
          <span></span>
        </Link>
      </div>
      <div className="home-link" id="about-link">
        <Link to="/about">About</Link>
      </div>
      <div className="home-link" id="works-link">
        <Link to="/other-works">Other Works</Link>
      </div>
      <div className="home-link" id="merch-link">
        <Link to="/merch">Merch</Link>
      </div>
    </section>
  );
};

export default Home;
