import React, { useState, useEffect } from "react";
import Luthier from "./Luthier";
import "./../index.css";
import { getPixelatedHi2Lo } from "../utils";

const bgImages = getPixelatedHi2Lo().map((url) => `url('${url}')`);

const LoFi: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (bgImages.length === 0) return;

    const changeBackgroundImages = async () => {
      for (let i = 0; i < bgImages.length; i++) {
        setCurrentIndex(i);
        await new Promise((resolve) => setTimeout(resolve, 500));
      }
    };

    changeBackgroundImages();

    // Cleanup function to clear the background image when the component unmounts
    return () => {
      document.body.style.backgroundImage = "";
    };
  }, []);

  useEffect(() => {
    document.body.style.backgroundImage = `url(${bgImages[currentIndex]})`;
  }, [currentIndex, bgImages]);

  return (
    <section id="lofi">
      <Luthier />
      <div id="equipment">
        <p id="equipment-header">making an open world</p>
        <p>Roland Juno 60</p>
        <p>Buchla Music Easel Command</p>
        <p>Buchla Music Easel (Arturia)</p>
        <p>Buchla 259e Twisted Waveform Generator (Softube)</p>
        <p>Buchla 296e Spectral Processor (Softube)</p>
        <p>Wagner Lenzen/DDR Upright Piano </p>
        <p>Kaayna Musicals Indian Classical Harmonium</p>
        <p>Peruvian Lap Harp</p>
        <p>Elektron Analog Rytm MK1 </p>
        <p>Yamaha FG200 Yellow Label</p>
        <p>Squier Mustang </p>
        <p>Shure SM57</p>
        <p>Shure SM58</p>
        <p>Neumann TLM 103</p>
        <p>Neumann KM184 </p>
        <p>Tascam Portastudio 414 MK2</p>
        <p>Ableton Live 11 </p>
        <p>Ableton Push 2 </p>
        <p>Ableton Convolution Reverb</p>
        <p>Ableton Granulator II </p>
        <p>Valhalla Delay</p>
        <p>Arturia Keystep</p>
        <p>Universal Audio Apollo X4</p>
        <p>Make Noise QPAS</p>
        <p>Make Noise Maths</p>
        <p>Make Noise Mimeophone </p>
        <p>Mutable Instruments Beads</p>
      </div>
    </section>
  );
};

export default LoFi;
